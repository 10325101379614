.App {
  text-align: center;
  margin: 0%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.App::-webkit-scrollbar {
  width: 0;
}

/* width */
.srollHiden::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.srollHiden::-webkit-scrollbar-thumb {
  background-color: #fff;
}

.srollHiden::-webkit-scrollbar-track {
  background-color: #fff;
}

/* width */
.srollStyle::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.srollStyle::-webkit-scrollbar-thumb {
  background-color: #0000003b;
  border-radius: 10px;
}

.srollStyle::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 10px;
}

body {
  margin: 0;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

@media only screen and (max-width: 500px) {
  .center-align {
    display: block;
  }
}
