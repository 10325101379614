.Mui-selected {
  /* background-color: black !important; */
  padding: 6px 12px !important;
  min-height: 12px !important;
  color: black !important;
  font-size: 12px !important;
  /* border-radius: 3px; */
}

.MuiTabs-indicator {
  background: black !important;
  height: 1px !important;
}

.MuiTabs-flexContainer {
  /* flex-wrap: "wrap"; */
}

.MuiTab-textColorPrimary {
  padding: 6px 12px !important;
  min-height: 12px !important;
  font-size: 12px !important;

}
.MuiTabs-root{
  min-height: 24px !important;
}