.history-container {
  width: 100%;
}

.MuiPaper-rounded.MuiPaper-elevation1.MuiCard-root {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.history-header {
  width: 100%;
  /* margin: 20px; */
  /* padding: 10px; */
  /* background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 10px; */
}

.history-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
.history-title-container h5 {
  margin: 0;
  padding: 0;
}

.expand-icon {
  transition: transform 0.3s ease;
  user-select: none;
  display: inline-block;
}

.expand-icon.open {
  transform: rotate(180deg);
}
