.center-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coaches_container {
  width: 1100px;
}

/* .coaches_container::-webkit-scrollbar {
  display: none;
} */

.session-book {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

@media only screen and (max-width: 500px) {
  .coaches_container::-webkit-scrollbar {
    display: none;
  }

  .coaches_container {
    width: auto;
  }
  /* 
  .history-container {
    display: flex;
    justify-content: center;
    align-items: center;
  } */
}

@media only screen and (max-width: 500px) {
  .session-book {
    gap: 5px;
  }
  .header-logo-container {
    padding: 20px;
  }
}

@media only screen and (max-width: 370px) {
  .session-book {
    box-sizing: border-box;
  }

  .time-mobile {
    font-size: 10px !important;
  }

  .small-device {
    width: 50% !important;
  }
  .session-day-title {
    font-size: 14px !important;
  }
}
