.splitScreen {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}
.topPane {
  grid-column: 1;
  grid-row: 1;
}

.bottomPane {
  grid-column: 2;
  grid-row: 1;
}

.profile {
  display: flex;
}

.profile-container {
  width: 100%;
  height: 600px;
}

.profile-container img {
  width: 100%;
  height: 600px;
}

@media only screen and (max-width: 500px) {
  .profile-container {
    width: 100%;
    height: 220px;
  }

  .profile-container img {
    width: 100%;
    height: 220px;
  }
}
